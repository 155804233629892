import * as DialogPrimitive from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/app/utils/misc";

import dialogStyles from "./dialog.module.css";

const dialogVariants = cva(`dialog`, {
  variants: {
    variant: {
      default: ``,
      scrollableContent: dialogStyles[`scrollable-content`],
      contrastHeader: dialogStyles[`contrast-header`],
      contrastHeaderScrollableContent: `${dialogStyles[`contrast-header`]} ${
      dialogStyles[`scrollable-content`]}`

    },
    size: {
      small: dialogStyles[`dialog-small`],
      medium: dialogStyles[`dialog-medium`],
      large: dialogStyles[`dialog-large`],
      full: dialogStyles[`dialog-full`]
    },
    maskVariant: {
      default: ``,
      transparent: dialogStyles[`transparent-mask`],
      smallPadding: dialogStyles[`small-padding-mask`]
    },
    position: {
      topRight: dialogStyles[`position-top-right`]
    }
  },
  defaultVariants: {
    variant: `default`
  }
});

interface DialogContentProps extends
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>,
  VariantProps<typeof dialogVariants> {
  bottomChildren?: React.ReactNode;
  disableOutsideClick?: boolean;
}

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>>(
  ({ className, ...props }, ref) =>
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      dialogStyles[`dialog-mask`],
      `data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0`,
      className
    )}
    {...props} />

);
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps>(

  (
  {
    className,
    children,
    variant,
    size,
    maskVariant,
    bottomChildren,
    position,
    disableOutsideClick,
    ...props
  },
  ref) =>

  <DialogPortal>
			<DialogOverlay
      className={cn(dialogVariants({ maskVariant, position }))} />

			<DialogPrimitive.Content
      ref={ref}
      className={cn(
        `duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]`,
        dialogStyles[`dialog-content`],
        dialogVariants({ variant, size, maskVariant, position }),
        className
      )}
      onInteractOutside={
      disableOutsideClick ?
      (e) => {
        e.preventDefault();
        e.stopPropagation();
      } :
      props.onInteractOutside
      }
      {...props}>

				{children}
			</DialogPrimitive.Content>
		</DialogPortal>

);
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) =>
<div className={cn(dialogStyles[`dialog-header`], className)} {...props} />;

DialogHeader.displayName = `DialogHeader`;

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) =>
<div className={cn(dialogStyles[`dialog-footer`], className)} {...props} />;

DialogFooter.displayName = `DialogFooter`;

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>>(
  ({ className, ...props }, ref) =>
  <DialogPrimitive.Title
    ref={ref}
    className={cn(dialogStyles[`dialog-title`], className)}
    {...props} />

);
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>>(
  ({ className, ...props }, ref) =>
  <DialogPrimitive.Description ref={ref} className={className} {...props} />
);
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  dialogVariants };


// EXAMPLE:
//
// <Dialog>
//   <DialogTrigger>Open</DialogTrigger>
//   <DialogContent>
//     <DialogHeader>
//       <DialogTitle>Are you absolutely sure?</DialogTitle>
//       <DialogDescription>
//         This action cannot be undone. This will permanently delete
//         your account and remove your data from our servers.
//       </DialogDescription>
//     </DialogHeader>
//     <DialogFooter>
//       <Button size="pill">Continue</Button>
//     </DialogFooter>
//   </DialogContent>
// </Dialog>